import Dexie, { Table } from 'dexie';
import { onGetAppKeyword } from '~/utils/helpers';
import { GroupCache, GroupNewsCache, GroupSupportCache, ArticlesCache, Notifications } from './dexieTypes';

const APP_KEYWORD = onGetAppKeyword();

export class DexieClass extends Dexie {
    groups!: Table<GroupCache>;
    groupNews!: Table<GroupNewsCache>;
    groupSupport!: Table<GroupSupportCache>;
    articles!: Table<ArticlesCache>;
    notifications!: Table<Notifications>

    constructor() {
        super(APP_KEYWORD);
        this.version(5).stores({
            groups: '_id',
            groupNews: 'groupId',
            groupSupport: 'groupId',
            articles: '_id',
            notifications: 'userId'
        });
    }
}

export const db = new DexieClass();
