/* eslint-disable camelcase */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FamilyGroup, FundraisingCharitySettings, FundraisingSettings, Service } from '~/types';

export type ProfileState = {
    id?: string,
    firstName?: string,
    lastName?: string,
    phoneNumbers?: string[],
    yearOfService?: { from: any, to: any };
    industries?: string[],
    email?: string,
    location?: any,
    photo?: any,
    work_experience?: Array<any>,
    group?: {
        id?: string,
        image?: string,
        name?: string,
        fundraising_settings?: FundraisingSettings,
        fundraising_charity_settings?: FundraisingCharitySettings
    },
    story?: string,
    my_story?: {
        date_updated?: string,
        story?: string,
        visibility?: string,
    },
    my_help?: {
        date_updated?: string,
        my_help?: string,
        visibility?: string
    },
    isEditing?: boolean,
    // prevProfile?: any
    connections?: any[],
    isFromIdentityPhoto?: boolean,
    connectionRequest?: any[],
    requestRejects?: any[],
    profileNotifications?: any,
    notificationStatus?: {
        connections: boolean,
        events: boolean,
        requests: boolean,
    },
    coordinates?: {
        lat: number,
        long: number
    },
    isShowError?: boolean,
    isShowSuccess?: boolean,
    isGroupOrSubGroupAdmin?: boolean,
    isHasSeen?: boolean,
    affiliatedRegiments?: any[],
    tempAffiliatedRegiments?: any[],
    regimentsAffiliations?: any[],
    service?: Service,
    family_group_id?: string,
    family_group?: {
        logo?: string,
        name?: string,
        title?: string,
        url?: string,
        land_scape?: string,
    },
    isUpdatedIgnore?: boolean,
    connections_and_others?: any[],
    connections_and_others_time?: number,
    registrationFamilyGroup?: FamilyGroup,
    hasNotifications?: boolean,
    hideContact?: boolean,
    college?: string,
    subject?: string
};

const initialState: ProfileState = {
    id: '',
    email: '',
    firstName: '',
    lastName: '',
    phoneNumbers: undefined,
    yearOfService: { from: undefined, to: undefined },
    industries: undefined,
    location: undefined,
    photo: undefined,
    work_experience: [],
    group: {
        id: '',
        name: '',
        image: ''
    },
    story: '',
    my_story: {
        date_updated: '',
        story: '',
        visibility: ''
    },
    my_help: {
        date_updated: '',
        my_help: '',
        visibility: ''
    },
    isEditing: false,
    // prevProfile: undefined,
    connections: [],
    isFromIdentityPhoto: false,
    connectionRequest: [],
    requestRejects: [],
    profileNotifications: {
        'THIS WEEK': [],
        EARLIER: []
    },
    notificationStatus: {
        connections: false,
        events: false,
        requests: false
    },
    isShowError: false,
    isGroupOrSubGroupAdmin: false,
    isShowSuccess: false,
    isHasSeen: false,
    affiliatedRegiments: [],
    tempAffiliatedRegiments: [],
    regimentsAffiliations: [],
    service: undefined,
    family_group_id: undefined,
    isUpdatedIgnore: false,
    connections_and_others: [],
    connections_and_others_time: 0,
    registrationFamilyGroup: undefined,
    hasNotifications: false,
    hideContact: false,
    college: '',
    subject: ''
};

export const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        setIndustries: (state, action: PayloadAction<ProfileState>) => {
            state.industries = action.payload.industries;
        },
        setProfile: (state, action: PayloadAction<ProfileState>) => {
            state.id = action.payload.id;
            state.firstName = action.payload.firstName;
            state.lastName = action.payload.lastName;
            state.phoneNumbers = action.payload.phoneNumbers;
            state.yearOfService = action.payload.yearOfService;
            state.email = action.payload.email?.toLowerCase();
            state.location = action.payload.location;

            state.work_experience = action.payload.work_experience;

            state.coordinates = action.payload.coordinates;

            state.isGroupOrSubGroupAdmin = action.payload.isGroupOrSubGroupAdmin;

            state.service = action.payload.service;

            state.family_group_id = action.payload.family_group_id;

            state.hideContact = action.payload.hideContact;

            if (action.payload.industries) state.industries = action.payload.industries;

            if (action.payload.photo || action.payload.isFromIdentityPhoto) state.photo = action.payload.photo;

            if (action.payload.group) state.group = action.payload.group;

            if (action.payload.story) state.story = action.payload.story;

            if (action.payload.connections) state.connections = action.payload.connections;

            if (action.payload.family_group) state.family_group = action.payload.family_group;

            state.college = action.payload.college;
            state.subject = action.payload.subject;
        },
        setEmail: (state, action: PayloadAction<ProfileState>) => {
            state.email = action.payload.email?.toLowerCase();
        },
        setLocation: (state, action: PayloadAction<ProfileState>) => {
            state.location = action.payload.location;
        },
        setPhoto: (state, action: PayloadAction<ProfileState>) => {
            state.photo = action.payload.photo;
        },
        setId: (state, action: PayloadAction<ProfileState>) => {
            state.id = action.payload.id;
        },
        setIdEmail: (state, action: PayloadAction<ProfileState>) => {
            state.id = action.payload.id;
            state.email = action.payload.email?.toLowerCase();
        },
        setPrimaryGroup: (state, action: PayloadAction<ProfileState>) => {
            state.group = { ...action.payload.group };
        },
        setWorkExperience: (state, action: PayloadAction<ProfileState>) => {
            state.work_experience = action.payload.work_experience;
        },
        setStoryAndHelp: (state, action: PayloadAction<ProfileState>) => {
            if (action.payload.my_story) {
                state.my_story = action.payload.my_story;
            }

            if (action.payload.my_help) {
                state.my_help = action.payload.my_help;
            }
        },
        setIsEditing: (state, action: PayloadAction<ProfileState>) => {
            state.isEditing = action.payload.isEditing;
        },
        setPrevProfile: (state, action: PayloadAction<ProfileState>) => {
            // state.prevProfile = action.payload.prevProfile;
        },
        clearPrevProfile: (state) => {
            // state.prevProfile = undefined;
        },
        setConnectionRequest: (state, action: PayloadAction<ProfileState>) => {
            state.connectionRequest = action.payload.connectionRequest;
        },
        setRequestRejects: (state, action: PayloadAction<ProfileState>) => {
            state.requestRejects = action.payload.requestRejects;
        },
        setProfileNotifications: (state, action: PayloadAction<ProfileState>) => {
            state.profileNotifications = action.payload.profileNotifications;
        },
        setNotificationStatus: (state, action: PayloadAction<ProfileState>) => {
            state.notificationStatus = action.payload.notificationStatus;
        },
        setCoordinates: (state, action: PayloadAction<ProfileState>) => {
            state.coordinates = action.payload.coordinates;
        },
        setIsShowError: (state, action: PayloadAction<ProfileState>) => {
            state.isShowError = action.payload.isShowError;
        },
        setIsShowSuccess: (state, action: PayloadAction<ProfileState>) => {
            state.isShowSuccess = action.payload.isShowSuccess;
        },
        setIsHasSeen: (state, action: PayloadAction<ProfileState>) => {
            state.isHasSeen = action.payload.isHasSeen;
        },
        clearProfile: () => ({
            ...initialState
        }),
        setConnections: (state, action: PayloadAction<ProfileState>) => {
            state.connections = action.payload.connections;
        },
        setAffiliatedRegiments: (state, action: PayloadAction<ProfileState>) => {
            state.affiliatedRegiments = action.payload.affiliatedRegiments;
        },
        setTempAffiliatedRegiments: (state, action: PayloadAction<ProfileState>) => {
            state.tempAffiliatedRegiments = action.payload.tempAffiliatedRegiments;
        },
        clearTempAffiliatedRegiments: (state) => {
            state.tempAffiliatedRegiments = [];
        },
        setRegimentsAffiliations: (state, action: PayloadAction<ProfileState>) => {
            state.regimentsAffiliations = action.payload.regimentsAffiliations;
        },
        setIsUpdatedIgnore: (state, action: PayloadAction<ProfileState['isUpdatedIgnore']>) => {
            state.isUpdatedIgnore = action.payload;
        },
        setConnectionsAndOthers: (state, action: PayloadAction<ProfileState>) => {
            state.connections_and_others = action.payload.connections_and_others;
            state.connections_and_others_time = action.payload.connections_and_others_time || 0;
        },
        setRegistrationFamilyGroup: (state, action: PayloadAction<ProfileState>) => {
            state.registrationFamilyGroup = action.payload.registrationFamilyGroup;
        },
        setHasNotifications: (state, action: PayloadAction<ProfileState>) => {
            state.hasNotifications = action.payload.hasNotifications;
        }
    }
});

export const { setProfile, setIndustries, setEmail, setLocation, setPhoto, setId, setIdEmail, setPrimaryGroup, setWorkExperience, setStoryAndHelp, setIsEditing, setPrevProfile, clearPrevProfile, setConnectionRequest, setRequestRejects, setProfileNotifications, setNotificationStatus, setCoordinates, setIsShowError, setIsShowSuccess, setIsHasSeen, clearProfile, setConnections, setAffiliatedRegiments, setTempAffiliatedRegiments, setRegimentsAffiliations, clearTempAffiliatedRegiments, setIsUpdatedIgnore, setConnectionsAndOthers, setRegistrationFamilyGroup, setHasNotifications } = profileSlice.actions;

export default profileSlice.reducer;
