import { configureStore, ThunkAction, Action, getDefaultMiddleware } from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import * as rootReducer from './reducers';
import Storage from '~/utils/Storage';
import { onGetAppKeyword } from '~/utils/helpers';

const APP_KEYWORD = onGetAppKeyword();

export const history = createBrowserHistory();

const persistedState = Storage.getItem(APP_KEYWORD) ? JSON.parse(Storage.getItem(APP_KEYWORD) || '{}') : {};

export const store = configureStore({
    reducer: {
        router: connectRouter(history),
        persistedState,
        ...rootReducer
    },
    preloadedState: persistedState,
    middleware: [routerMiddleware(history), ...getDefaultMiddleware({ serializableCheck: false })],
    devTools: process.env.NODE_ENV !== 'production'
});

store.subscribe(() => {
    Storage.setItem(APP_KEYWORD, JSON.stringify(store.getState()));
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
