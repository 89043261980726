import PageLoadable from 'components/modules/Loadable/PageLoadable';

const AsyncNotFound = PageLoadable({ loader: () => import('./NotFound') });
const AsyncLogin = PageLoadable({ loader: () => import('./Login') });
const AsyncDashboard = PageLoadable({ loader: () => import('./Dashboard') });
const AsyncSignUp = PageLoadable({ loader: () => import('./SignUp') });
const AsyncChats = PageLoadable({ loader: () => import('./Chats') });
// const AsyncRegister = PageLoadable({ loader: () => import('./Register') });
const AsyncCreatePassword = PageLoadable({ loader: () => import('./CreatePassword') });
const AsyncUpdateProfile = PageLoadable({ loader: () => import('./UpdateProfile') });
const AsyncPinboard = PageLoadable({ loader: () => import('./Pinboard') });
const AsyncIdentity = PageLoadable({ loader: () => import('./Identity') });
const AsyncStory = PageLoadable({ loader: () => import('./Story') });
const AsyncHowCanIHelp = PageLoadable({ loader: () => import('./HowCanIHelp') });
const AsyncTourBase = PageLoadable({ loader: () => import('./TourBase') });
const AsyncResetPassword = PageLoadable({ loader: () => import('./ResetPassword') });
const AsyncOptions = PageLoadable({ loader: () => import('./Options') });
const AsyncMyConnections = PageLoadable({ loader: () => import('./MyConnections') });
// const AsyncNotifications = PageLoadable({ loader: () => import('./Notifications') });
const AsyncArticle = PageLoadable({ loader: () => import('./Article') });
const AsyncEvents = PageLoadable({ loader: () => import('./Events') });
const AsyncGroup = PageLoadable({ loader: () => import('./Group') });
const AsyncMember = PageLoadable({ loader: () => import('./Member') });
const AsyncGroupAbout = PageLoadable({ loader: () => import('./GroupAbout') });
const AsyncGroupMembers = PageLoadable({ loader: () => import('./Members') });
const AsyncPinboardQuestionnaire = PageLoadable({ loader: () => import('./PinboardQuestionnaire') });
const AsyncManageGroup = PageLoadable({ loader: () => import('./ManageGroup') });
const AsyncUpdateGroup = PageLoadable({ loader: () => import('./UpdateGroup') });
const AsyncModerateMembers = PageLoadable({ loader: () => import('./ModerateMembers') });
const AsyncModerateArticle = PageLoadable({ loader: () => import('./ModerateArticle') });
const AsyncModerateArticleDetails = PageLoadable({ loader: () => import('./ModerateArticleDetails') });
const AsyncArticleEditor = PageLoadable({ loader: () => import('./ArticleEditor') });
const AsyncEventEditor = PageLoadable({ loader: () => import('./EventEditor') });
const AsyncCreateEventChoices = PageLoadable({ loader: () => import('./CreateEventChoices') });
const AsyncCreateEventFromPrev = PageLoadable({ loader: () => import('./CreateEventFromPrev') });
const AsyncEvent = PageLoadable({ loader: () => import('./Event') });
const AsyncPublicEvent = PageLoadable({ loader: () => import('./PublicEvent') });
const AsyncReviewEvents = PageLoadable({ loader: () => import('./ReviewEvents') });
const AsyncModerateEventCreatorsAndPublishers = PageLoadable({ loader: () => import('./ModerateEventCreatorsAndPublishers') });
const AsyncGroupSections = PageLoadable({ loader: () => import('./GroupSections') });
const AsyncPollResults = PageLoadable({ loader: () => import('./PollResults') });
const AsyncPolls = PageLoadable({ loader: () => import('./Polls') });
const AsyncQuestionResults = PageLoadable({ loader: () => import('./QuestionResults') });
const AsyncCreateGroup = PageLoadable({ loader: () => import('./CreateGroup') });
const AsyncSupportSuite = PageLoadable({ loader: () => import('./SupportSuite') });
const AsyncBulkMessaging = PageLoadable({ loader: () => import('./BulkMessaging') });
const AsyncAdmin = PageLoadable({ loader: () => import('./Admin') });
const AsyncMaintenance = PageLoadable({ loader: () => import('./Maintenance') });
const AsyncInitialRoute = PageLoadable({ loader: () => import('./InitialRoute') });
const AsyncRegisterFromGroupCode = PageLoadable({ loader: () => import('./RegisterFromGroupCode') });
const AsyncPrimaryGroupSelection = PageLoadable({ loader: () => import('./PrimaryGroupSelection') });
const AsyncRegistrationEmail = PageLoadable({ loader: () => import('./RegistrationEmail') });
const AsyncRegistrationActivactionCode = PageLoadable({ loader: () => import('./RegistrationActivactionCode') });
const AsyncLoginPassword = PageLoadable({ loader: () => import('./LoginPassword') });
const AsyncLoginEmail = PageLoadable({ loader: () => import('./LoginEmail') });
const AsyncRegisterRequestGroupCode = PageLoadable({ loader: () => import('./RegisterRequestGroupCode') });
const AsyncSupports = PageLoadable({ loader: () => import('./Supports') });
const AsyncPublicArticle = PageLoadable({ loader: () => import('./PublicArticle') });
const AsyncExternalPage = PageLoadable({ loader: () => import('./ExternalPage') });
const AsyncEventAdminResult = PageLoadable({ loader: () => import('./EventAdminResult') });
const AsyncPublicCampaign = PageLoadable({ loader: () => import('./PublicCampaign') });
const AsyncPublicCampaignSuccess = PageLoadable({ loader: () => import('./PublicCampaignSuccess') });
const AsyncPublicCampaignDonate = PageLoadable({ loader: () => import('./PublicCampaignDonate') });
const AsyncFinancialAdmin = PageLoadable({ loader: () => import('./FinancialAdmin') });
const AsyncStripeOnboardingSuccess = PageLoadable({ loader: () => import('./StripeOnboardingSuccess') });
// ROUTE IMPORT CODE GENERATOR INDICATOR DO NOT DELETE

const routes = {
    LOGIN_EMAIL: {
        component: AsyncLoginEmail,
        path: '/login/email',
        authedRoute: false
    },
    LOGIN_PASSWORD: {
        component: AsyncLoginPassword,
        path: '/login/password',
        authedRoute: false
    },
    LOGIN: {
        component: AsyncLogin,
        path: '/login',
        authedRoute: false
    },
    DASHBOARD: {
        component: AsyncDashboard,
        path: '/dashboard',
        authedRoute: true
    },
    SIGN_UP: {
        component: AsyncSignUp,
        path: '/sign-up',
        authedRoute: false
    },
    POLL_RESULTS: {
        component: AsyncPollResults,
        path: '/chats/poll/results',
        authedRoute: true
    },
    POLLS: {
        component: AsyncPolls,
        path: '/chats/polls',
        authedRoute: true
    },
    CHATS: {
        component: AsyncChats,
        path: '/chats',
        authedRoute: true
    },
    // REGISTER: {
    //     component: AsyncRegister,
    //     path: '/register',
    //     authedRoute: false
    // },
    CREATE_PASSWORD: {
        component: AsyncCreatePassword,
        path: '/create-password',
        authedRoute: false
    },
    UPDATE_PROFILE: {
        component: AsyncUpdateProfile,
        path: '/update-profile',
        authedRoute: false
    },
    PINBOARD_QUESTIONNAIRE: {
        component: AsyncPinboardQuestionnaire,
        path: '/pinboard/questionnaire',
        authedRoute: true
    },
    PINBOARD: {
        component: AsyncPinboard,
        path: '/pinboard',
        authedRoute: true
    },
    OPTIONS: {
        component: AsyncOptions,
        path: '/identity/options',
        authedRoute: true
    },
    IDENTITY: {
        component: AsyncIdentity,
        path: '/identity',
        authedRoute: true
    },
    STORY: {
        component: AsyncStory,
        path: '/story',
        authedRoute: true
    },
    HOW_CAN_I_HELP: {
        component: AsyncHowCanIHelp,
        path: '/how-can-i-help',
        authedRoute: true
    },
    TOUR_BASE: {
        component: AsyncTourBase,
        path: '/tour-base',
        authedRoute: true
    },
    RESET_PASSWORD: {
        component: AsyncResetPassword,
        path: '/reset-password',
        authedRoute: false
    },
    MY_CONNECTIONS: {
        component: AsyncMyConnections,
        path: '/my-connections',
        authedRoute: true
    },
    // NOTIFICATIONS: {
    //     component: AsyncNotifications,
    //     path: '/notifications',
    //     authedRoute: true
    // },
    ARTICLE: {
        component: AsyncArticle,
        path: '/article',
        authedRoute: true
    },
    GROUP_MEMBERS: {
        component: AsyncGroupMembers,
        path: '/groups/members',
        authedRoute: true
    },
    CREATE_GROUP: {
        component: AsyncCreateGroup,
        path: '/groups/create',
        authedRoute: true
    },
    GROUP_ABOUT: {
        component: AsyncGroupAbout,
        path: '/groups/about',
        authedRoute: true
    },
    MODERATE_MEMBERS: {
        component: AsyncModerateMembers,
        path: '/groups/:groupId/manage/members',
        authedRoute: true
    },
    MODERATE_NEWS_DETAILS: {
        component: AsyncModerateArticleDetails,
        path: '/groups/manage/news/details',
        authedRoute: true
    },
    ARTICLE_EDITOR_NEWS: {
        component: AsyncArticleEditor,
        path: '/groups/manage/news/edit',
        authedRoute: true
    },
    CREATE_ARTICLE_NEWS: {
        component: AsyncArticleEditor,
        path: '/groups/manage/news/create',
        authedRoute: true
    },
    MODERATE_NEWS: {
        component: AsyncModerateArticle,
        path: '/groups/manage/news',
        authedRoute: true
    },
    MODERATE_SUPPORT_DETAILS: {
        component: AsyncModerateArticleDetails,
        path: '/groups/manage/support/details',
        authedRoute: true
    },
    ARTICLE_EDITOR_SUPPORT: {
        component: AsyncArticleEditor,
        path: '/groups/manage/support/edit',
        authedRoute: true
    },
    CREATE_ARTICLE_SUPPORT: {
        component: AsyncArticleEditor,
        path: '/groups/manage/support/create',
        authedRoute: true
    },
    MODERATE_SUPPORT: {
        component: AsyncModerateArticle,
        path: '/groups/manage/support',
        authedRoute: true
    },
    CREATE_EVENT_FROM_PREV: {
        component: AsyncCreateEventFromPrev,
        path: '/groups/manage/events/create/previous',
        authedRoute: true
    },
    CREATE_EVENT_CHOICES: {
        component: AsyncCreateEventChoices,
        path: '/groups/manage/events/create',
        authedRoute: true
    },
    EVENT_EDITOR: {
        component: AsyncEventEditor,
        path: '/groups/manage/events/editor',
        authedRoute: true
    },
    MODERATE_EVENT_CREATORS_AND_PUBLISHERS: {
        component: AsyncModerateEventCreatorsAndPublishers,
        path: '/groups/manage/events/creators-and-publishers',
        authedRoute: true
    },
    REVIEW_EVENTS: {
        component: AsyncReviewEvents,
        path: '/groups/:groupId/manage/events',
        authedRoute: true
    },
    GROUP_SECTIONS: {
        component: AsyncGroupSections,
        path: '/groups/:groupId/manage/sections',
        authedRoute: true
    },
    MANAGE_GROUP: {
        component: AsyncManageGroup,
        path: '/groups/manage',
        authedRoute: true
    },
    UPDATE_GROUP: {
        component: AsyncUpdateGroup,
        path: '/groups/update',
        authedRoute: true
    },
    GROUP_WITH_ID: {
        component: AsyncGroup,
        path: '/groups/:groupId',
        authedRoute: true
    },
    GROUP: {
        component: AsyncGroup,
        path: '/groups',
        authedRoute: true
    },
    PUBLIC_EVENT: {
        component: AsyncPublicEvent,
        path: '/public-events',
        authedRoute: false
    },
    QUESTION_RESULTS: {
        component: AsyncQuestionResults,
        path: '/events/:eventId/results',
        authedRoute: true
    },
    EVENT_ADMIN_RESULT: {
        component: AsyncEventAdminResult,
        path: '/events/:eventId/event-results',
        authedRoute: true
    },
    EVENT_MEMBERS: {
        component: AsyncGroupMembers,
        path: '/events/members',
        authedRoute: true
    },
    EVENTS_WITH_ID: {
        component: AsyncEvents,
        path: '/events/:eventId',
        authedRoute: true
    },
    EVENT: {
        component: AsyncEvent,
        path: '/event/:eventId',
        authedRoute: true
    },
    EVENTS: {
        component: AsyncEvents,
        path: '/events',
        authedRoute: true
    },
    MEMBER: {
        component: AsyncMember,
        path: '/members/:memberId',
        authedRoute: true
    },
    SUPPORT_SUITE: {
        component: AsyncSupportSuite,
        path: '/support-suite',
        authedRoute: true
    },
    BULK_MESSAGING: {
        component: AsyncBulkMessaging,
        path: '/bulk-messaging',
        authedRoute: true
    },
    ADMIN: {
        component: AsyncAdmin,
        path: '/admin',
        authedRoute: true
    },
    MAINTENANCE: {
        component: AsyncMaintenance,
        path: '/maintenance',
        authedRoute: false
    },
    PRIMARY_GROUP_SELECTION: {
        component: AsyncPrimaryGroupSelection,
        path: '/register/group',
        authedRoute: false
    },
    REGISTRATION_EMAIL: {
        component: AsyncRegistrationEmail,
        path: '/register/email',
        authedRoute: false
    },
    REGISTRATION_ACTIVACTION_CODE: {
        component: AsyncRegistrationActivactionCode,
        path: '/register/activate',
        authedRoute: false
    },
    REGISTER_REQUEST_GROUP_CODE: {
        component: AsyncRegisterRequestGroupCode,
        path: '/register/request-group-code',
        authedRoute: false
    },
    REGISTER_FROM_GROUP_CODE: {
        component: AsyncRegisterFromGroupCode,
        path: '/register',
        authedRoute: false
    },
    SUPPORTS: {
        component: AsyncSupports,
        path: '/supports',
        authedRoute: false
    },
    PUBLIC_ARTICLE: {
        component: AsyncPublicArticle,
        path: '/public-article',
        authedRoute: false
    },
    EXTERNAL_PAGE: {
        component: AsyncExternalPage,
        path: '/embedded',
        authedRoute: true
    },
    PUBLIC_CAMPAIGN: {
        component: AsyncPublicCampaign,
        path: '/public-campaign/:custom_link',
        authedRoute: false
    },
    PUBLIC_CAMPAIGN_SUCCESS: {
        component: AsyncPublicCampaignSuccess,
        path: '/public-campaign-success',
        authedRoute: false
    },
    PUBLIC_CAMPAIGN_DONATE: {
        component: AsyncPublicCampaignDonate,
        path: '/public-campaign-donate',
        authedRoute: false
    },
    PUBLIC_CAMPAIGN_NO_CUSTOM_LINK: {
        component: AsyncPublicCampaign,
        path: '/public-campaign',
        authedRoute: false
    },
    FINANCIAL_ADMIN: {
        component: AsyncFinancialAdmin,
        path: '/financial-admin',
        authedRoute: true
    },
    STRIPE_ONBOARDING_SUCCESS: {
        component: AsyncStripeOnboardingSuccess,
        path: '/public-payment-onboarding/success',
        authedRoute: false
    },
    // ROUTE ENTRY CODE GENERATOR INDICATOR DO NOT DELETE
    INITIAL_ROUTE: {
        component: AsyncInitialRoute,
        path: '/',
        authedRoute: false
    },
    NOT_FOUND: {
        component: AsyncNotFound,
        path: '*',
        authedRoute: false
    }
};

export default routes;
