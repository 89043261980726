import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type GroupRolesState = {
    groupRoles?: any,
    hasCharity?: boolean;
};

const initialState: GroupRolesState = {
    groupRoles: {},
    hasCharity: false
};

export const groupRolesSlice = createSlice({
    name: 'groupRoles',
    initialState,
    reducers: {
        setGroupRoles: (state, action: PayloadAction<GroupRolesState>) => {
            state.groupRoles = action.payload.groupRoles;
        },
        setHasCharity: (state, action: PayloadAction<boolean>) => {
            state.hasCharity = action.payload;
        }
    }
});

export const { setGroupRoles, setHasCharity } = groupRolesSlice.actions;

export default groupRolesSlice.reducer;
