import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { APP } from '~/types';

const useHref = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const app = (searchParams.get('app') || 'ma')?.toLowerCase();

    const [isMilitary, setIsMilitary] = useState(app === 'ma');
    const [isFusiliers, setIsFusiliers] = useState(app === 'fc');
    const [isRoyalAnglianConnect, setIsRoyalAnglianConnect] = useState(app === 'ra');
    const [isOUBC, setIsOUBC] = useState(app === 'oubc');
    const [isSyracuseRowing, setIsSyracuseRowing] = useState(app === 'cuse');

    const getAppName = (): APP => {
        if (isFusiliers) return 'Fusiliers';
        if (isRoyalAnglianConnect) return 'Royal Anglian Connect';
        if (isOUBC) return 'OUBC';
        if (isSyracuseRowing) return 'Syracuse Rowing';

        return 'Military App';
    };

    const appName = useMemo(() => getAppName(), [isFusiliers, isRoyalAnglianConnect, isOUBC]);

    useEffect(() => {
        if (app) {
            setIsMilitary(app === 'ma');
            setIsFusiliers(app === 'fc');
            setIsRoyalAnglianConnect(app === 'ra');
            setIsOUBC(app === 'oubc');
            setIsSyracuseRowing(app === 'cuse');
        } else {
            setIsMilitary(true);
        }
    }, [window.location]);

    return {
        isMilitary,
        isFusiliers,
        isRoyalAnglianConnect,
        isOUBC,
        isSyracuseRowing,
        app,
        appName
    };
};

export default useHref;
