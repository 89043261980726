import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type AssociatedGroupsState = {
    associatedGroups: any
};

const initialState: AssociatedGroupsState = {
    associatedGroups: {}
};

export const associatedGroupsSlice = createSlice({
    name: 'associatedGroups',
    initialState,
    reducers: {
        setAssociatedGroups: (state, action: PayloadAction<AssociatedGroupsState>) => {
            state.associatedGroups = { ...state.associatedGroups, ...action.payload.associatedGroups };
        }
    }
});

export const { setAssociatedGroups } = associatedGroupsSlice.actions;

export default associatedGroupsSlice.reducer;
