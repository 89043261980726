/* eslint-disable space-before-function-paren */
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { setIsShowedOpenAppPopup } from '~/redux/modules/authentication';
import { isMobileOnly } from 'react-device-detect';
import { getQueryParam } from '~/utils/helpers';

const useShowOpenAppPopup = () => {
    const { isShowedOpenAppPopup } = useAppSelector(({ authentication }) => authentication);
    const dispatch = useAppDispatch();
    const isPublicCampaign = window.location.pathname.includes('public-campaign');
    const isPublicCampaignDonate = window.location.pathname.includes('public-campaign-donate');
    const isFinancialAdmin = window.location.pathname.includes('financial-admin');

    const isHidden = getQueryParam('pop-up') === 'false' || getQueryParam('id') || isPublicCampaign || isPublicCampaignDonate || isFinancialAdmin;

    const handleBeforeUnload = () => {
        dispatch(setIsShowedOpenAppPopup({ isShowedOpenAppPopup: false }));
    };

    useEffect(() => {
        if (isMobileOnly) {
            dispatch(setIsShowedOpenAppPopup({ isShowedOpenAppPopup: false }));
        }
    }, []);

    useEffect(() => {
        if (process.env.REACT_APP_ENV === 'dev' || isPublicCampaign || isPublicCampaignDonate || isFinancialAdmin) {
            return;
        }

        window.addEventListener('beforeunload', function (e) {
            e.preventDefault();

            e.returnValue = '';

            handleBeforeUnload();
        });

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    if (isHidden) {
        return true;
    }

    return isMobileOnly && !isHidden ? isShowedOpenAppPopup : true;
};

export default useShowOpenAppPopup;
